import { ApiError, AtreusUser, UserService } from '@atreus/data';
import { Tracker } from '@atreus/tracker';
import { createStore } from '@qcx/store';
import { route } from '../../route';
import { useEffect, useRef } from 'react';

const productKeyTitleMap = new Map<string, string>([
  ['concursos', 'Concursos Públicos'],
  ['enem', 'ENEM'],
  ['militares', 'Militares'],
  ['oab', 'OAB'],
  ['vestibular', 'Vestibular']
]);

export interface MenuItem {
  id?: string;
  title: string;
  href: string;
  event?: string;
  children?: MenuItem[];
  props?: React.AnchorHTMLAttributes<HTMLAnchorElement & HTMLButtonElement>;
}

export interface ShellStoreState {
  currentUser?: AtreusUser;
  isCurrentUserFetched?: boolean;
  isZarazFetched?: boolean;
  isShellReady?: boolean;
  isLoadingUser: boolean;
  isMenuOpen: boolean;
  menuItems: MenuItem[];
}

const initialState: ShellStoreState = {
  isCurrentUserFetched: false,
  isZarazFetched: false,
  isShellReady: false,
  isLoadingUser: true,
  isMenuOpen: false,
  menuItems: [
    {
      id: '1',
      title: 'Notícias de Concursos',
      href: route('news-index')
    },
    {
      id: '2',
      title: 'Concursos Abertos',
      href: route('editorials-show', { slug: 'concursos-abertos' })
    },
    {
      id: '3',
      title: 'Concursos Previstos',
      href: route('editorials-show', { slug: 'concursos-previstos' })
    },
    {
      id: '4',
      title: 'Ebooks',
      href: route('editorials-show', { slug: 'e-books' })
    },
    {
      id: '5',
      title: 'Editorias',
      href: route('editorials-index')
    },
    {
      id: '6',
      title: 'Vídeos',
      href: 'https://www.youtube.com/folhadirigida',
      props: {
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    }
  ]
};

const { Provider, useStore } = createStore(
  initialState,
  ({ get, set, onMount, subscribe }) => {
    subscribe(state => {
      if (state.isShellReady) {
        return;
      }

      const { currentUser, isCurrentUserFetched, isZarazFetched } = state;

      if (isCurrentUserFetched && isZarazFetched) {
        set({ isShellReady: true });
      }

      if (!currentUser) {
        return;
      }

      const productTitle =
        productKeyTitleMap.get(
          currentUser.ldContext.custom.preferredProduct || 'concursos'
        ) || 'Concursos Públicos';

      setGlobalUserSession({
        id: currentUser.id,
        email: currentUser.email,
        name: currentUser.name,
        subscription: {
          activeRecurrence: Boolean(currentUser.ldContext.custom.recurrence),
          level: currentUser.subscriptionStatus || 'standard',
          daysRemaining: currentUser.ldContext.custom.daysRemaining || 0
        },
        product: {
          title: productTitle
        }
      });

      if (isCurrentUserFetched && isZarazFetched) {
        if (window.zaraz) {
          window.zaraz.set('userId', currentUser.id);
          window.zaraz.set('user_subscription', currentUser.subscriptionStatus);
        }

        Tracker.identify(
          String(currentUser.id),
          {
            name: currentUser.name,
            email: currentUser.email,
            subscription: currentUser.subscriptionStatus || 'standard',
            'days remaining': currentUser.ldContext.custom.daysRemaining || 0,
            recurrence: Boolean(currentUser.ldContext.custom.recurrence),
            segment: productTitle
          },
          {}
        );
      }
    });

    onMount(() => {
      if (get().isCurrentUserFetched) {
        return;
      }

      set({ isLoadingUser: true });

      const fetchCurrentUser = async () => {
        const userService = new UserService({ credentials: 'include' });

        try {
          const user = await userService.getAtreusUser();

          setGlobalUserSession({
            id: user.id,
            email: user.email,
            name: user.name,
            subscription: {
              activeRecurrence: Boolean(user.ldContext.custom.recurrence),
              level: user.subscriptionStatus || 'standard',
              daysRemaining: user.ldContext.custom.daysRemaining || 0
            },
            product: {
              title:
                productKeyTitleMap.get(
                  user.ldContext.custom.preferredProduct || 'concursos'
                ) || 'Concursos Públicos'
            }
          });

          set({
            currentUser: user,
            isLoadingUser: false,
            isCurrentUserFetched: true
          });
        } catch (error) {
          if (error instanceof ApiError && error.status === 401) {
            set({
              currentUser: undefined,
              isLoadingUser: false,
              isCurrentUserFetched: true
            });
          }
        }
      };

      fetchCurrentUser();
    });

    return {
      setZarazFetched: () => set({ isZarazFetched: true }),
      toggleMenu: (value?: boolean) => {
        set(({ isMenuOpen }) => ({
          isMenuOpen: value || !isMenuOpen
        }));
      }
    };
  }
);

const setGlobalUserSession = (
  user:
    | {
        id: number;
        email: string;
        name: string;
        subscription: {
          activeRecurrence: boolean;
          level: string;
          daysRemaining: number;
        };
        product: {
          title: string;
        };
      }
    | undefined
) => {
  (window as unknown as { __userSession: typeof user }).__userSession = user
    ? {
        id: user.id,
        email: user.email,
        name: user.name,
        subscription: {
          activeRecurrence: user.subscription.activeRecurrence,
          level: user.subscription.level,
          daysRemaining: user.subscription.daysRemaining
        },
        product: {
          title: user.product.title
        }
      }
    : undefined;
};

export const useCurrentUser = () => useShellStore(s => s.currentUser);

export const useIsUserAuthenticated = (): boolean => Boolean(useCurrentUser());

export const useShellReady = (cb: () => void) => {
  const ref = useRef(false);
  const isShellReady = useShellStore(s => s.isShellReady);

  useEffect(() => {
    if (ref.current || !isShellReady) {
      return;
    }

    cb();
    ref.current = true;
  }, [cb, isShellReady]);
};

export const ShellStoreProvider = Provider;
export const useShellStore = useStore;
